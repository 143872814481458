
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper domgas">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Domestic Gas</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Full inspections to guarantee the right options for your home
                    </h3>
                    <p class="client-service">
                      Ignite Facilities have a dedicated team to handle all of your queries and gas services for your home. We will carry out thorough inspections before providing the best option to you. We specialise in installation, maintenance, repairs and servicing.
                    </p>

                    <h6>
                      If you're after Commercial Heating, click here
                    </h6>
                    <div>
                      <router-link to="Heating">
                        <button class="client-button-orange-solid">
                          Click here
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/gallery/31.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid domgas panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Boiler Replacements</h3>
                      <p class="panel-excerpt">Our experienced engineers carry out a vast range of boiler replacements, whether you are replacing an old boiler or if you are looking to upgrade to a new energy efficient boiler, our engineers will take care of it all.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Energy Efficient Review</h3>
                      <p class="panel-excerpt">We provide energy efficiency reviews to <em>customers across Staffordshire and Cheshire</em> to help them better understand their energy usage and where improvements can be made within their home.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Boiler Installation and System Upgrades</h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">Domestic Combi, System</em> and <em style="color: #f87e35;">Conventional Boilers. Water Storage Tanks</em> and <em style="color: #f87e35;">Hot Water Cylinders</em> plus added <em style="color: #f87e35;">Smart Controls</em> for residential properties. <em style="color: #f87e35;">Radiators</em> are fitted by our team.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Energy Efficiency Smart Controls</h3>
                      <p class="panel-excerpt">A great way for many home owners to save money on their utility bills is by having an <em>Energy Efficient Smart Controls</em> installed. Ignite Facilities install a wide variety of domestic smart thermostats and <em>TMVs</em> to homes across Staffordshire and Cheshire.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Property Utilities Management</h3>
                      <p class="panel-excerpt">Ignite Facilities provides a comprehensive Utilities Management service for <em>Letting Agents</em> and <em>Property Landlords</em> who have an extensive property portfolio. We carry out all compliance checks, regulation certificates and maintenance works to give you peace of mind.</p>
                    </div>
                  </div>	      	
                </div>                
              </div>
            </div>
          </div>
        </section> 
        
        <!-- Home about section -->
        <div class="masonry-wrapper domgas-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Crowdfunding",
  metaInfo: {
    title: 'Domestic Gas - Nationwide Gas Care Services',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Domestic Gas - Nationwide Gas Care Services | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Trust Ignite Facilities for reliable domestic gas services nationwide. Contact us for all your gas care needs.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/domgas");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
