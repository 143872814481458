<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="gyms"
      itemscope
      itemtype="http://schema.org/WebPage"
    >

    <div class="masonry-wrapper emergencies">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Emergencies</h1>
        </div>            
      </div>      
    </div>

    <main class="main-wrap">
      <div class="content-wrap">

        <!-- About us Section-->
        
        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Gyms",
  metaInfo: {
    title: 'Emergencies',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Emergencies | Ignite Facilities'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/emergencies");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.training {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
