<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="home"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
    <section class="video-player">
      <div class="video-cont avoid-clicks">
        <div style="position:relative;">
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/688041664?h=3c5efb149b&amp;background=1&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;badge=1&amp;autopause=0&amp;player_id=1&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ignite.mp4"></iframe>
          </div>
        </div>                      
      </div>       
    </section>    

    <div class="masonry-wrapper blue">
      <div class="masonry home-services">      
        <div class="overlay masonry-item text">
          <div class="masonry-title">
            <h2>
              One call. One solution
            </h2>
            <h3 class="sub-heading">
              No property is too big or too small
            </h3>
            <p>
              We take care of all maintenance, repairs and installations for commercial properties throughout Staffordshire. From heating and plumbing services to air conditioning and electrical installations - we take care of property needs for warehouses, leisure facilities, tourist attractions, hotels and much more. No property is too big or too small for Ignite Facilities. We also provide emergency call out services offering the one solution to fit all.
            </p>
            <div>
              <router-link to="Work">
                <button class="client-button-orange-solid">
                  See our work
                </button>
              </router-link>                  
            </div> 
          </div>
        </div>
        <div class="overlay masonry-item image" >
        </div>              
      </div>
    </div>

    <CaseStudies />

    <main class="main-wrap">
      <div class="content-wrap">

        <!-- Services Boxes -->
        <section id="services" class="secondary-back home">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="title-row">
                  <h2 class="section-title">Over 25 years of experience means we deliver the right support for your business.</h2>
                </div>
              </div>
              <div class="blog-archive">
                <article class="blog-article heating-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Heating
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Multi-skilled engineers on hand 24/7 to cater for all your commercial needs. 
                      
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="Heating" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>

                <article class="blog-article plumbing-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Plumbing
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Water hygiene to leaky plumbing, we take away the stress of managing it all.
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="Plumbing" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>

                <article class="blog-article electrical-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Electrical
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Ensure your business is as safe and efficient as it can be with our expert electrical engineers. 
                      
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="Electrical" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>
                
                <article class="blog-article air-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Air-Con
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Future proof your business with managed Air-Con systems that save you money.
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="AirCon" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>

                <article class="blog-article gas-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Domestic Gas
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Does your boiler need an upgrade? All of our installs come with full manufacturer warranty.
                      
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="DomGas" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>

                <article class="blog-article emergencies-services-bg"> 
                  <div class="blog-article-text-container">
                    <h3>
                      Emergencies
                    </h3>
                    <div >
                      <p class="blog-article-text">
                      Keep your business downtime to a minimum with our emergency response.
                      </p>
                    </div>
                    <div class="mt-16 blog-article-button-container">
                      <router-link to="Emergencies" class="mt-16 blog-article-button-container">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>                    
                  </div>
                </article>

              </div>
            </div>
          </div>
        </section>

        <!-- Client Review Slider -->
        <!-- <ClientReviewCarousel/> -->

        <!-- Home about section -->
        <div class="masonry-wrapper grey">
          <div class="masonry home-services">      
            
            <div class="overlay masonry-item image" >
            </div>
            <div class="overlay masonry-item text">
              <div class="masonry-title">
                <h2>
                  About Us
                </h2>
                <h3 class="sub-heading">
                  25 years experience
                </h3>
                <p>
                  Our team of highly skilled engineers all share the same values of providing the best customer service in everything we do. With more than 25 years experience our team is on hand to help you to find the best solution for your heating, plumbing or electrical issues. We are based in Staffordshire, employ local people and we help local businesses to provide the best experience for their customers.                   
                </p>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Read More
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>              
          </div>
        </div>
          
        <!-- Support Packages -->
        <section id="services" class="quarternary-back">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="title-row">
                  <h2 class="section-title">Supporting businesses with dedicated packages
               </h2>
                </div>
              </div>

              <div class="services-cont blue">

                <div class="service">
                  <div>
                    <i class="service__icon o-call-contact-1" alt="call"></i>
                    <h4 class="service__title">One call one solution</h4>
                    <p class="service__text">
                      Minimise your lost time calling multiple contractors we aim to cover all of you <em style="color: #ffffff!important;">facility management</em> needs in one.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <i class="service__icon o-wall-clock-1" alt="speed"></i>
                    <h4 class="service__title">Speedy Response</h4>
                    <p class="service__text">
                      We aim to provide a speedy response and a <em style="color: #ffffff!important;">first time fix</em> in order to minimise your companies Downtime.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <i class="service__icon o-security-shield-1" alt="protection"></i>
                    <h4 class="service__title">Protect your business</h4>
                    <p class="service__text">
                      From annual checks to emergency response, protect your business with <em style="color: #ffffff!important;">Ignite Facilities</em>.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <i class="service__icon o-settings-1" alt="engineer"></i>
                    <h4 class="service__title">Multi-skilled Engineers</h4>
                    <p class="service__text">
                      Our multi-skilled engineers provide electrical and plumbing <em style="color: #ffffff!important;">solutions</em> to enable your <em style="color: #ffffff!important;">business</em> to operate at <em style="color: #ffffff!important;">full speed</em>.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- end content wrap -->
      <!-- Start Case Studies -->
      <div id="back-to-top">
        <a href="#top">
          <i class="ui-arrow-up"></i>
        </a>
      </div>
    </main>
    <!-- end main wrapper -->
  </body>
</template>

<script src="https://player.vimeo.com/api/player.js"></script>


<script>

import FaqItem from "@/components/FaqItem.vue";
import CaseStudies from "@/components/ClientCarousel.vue";
import ClientReviewCarousel from "@/components/ClientReviewCarousel.vue";
export default {
  name: "home",
  metaInfo: {
    title: 'Home - Premier Mechanical & Plumbing Services',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Ignite Facilities'
      },
      { 
        vmid: 'description',
        property: 'og:description',
        content: 'Ignite Facilities offers premier mechanical and plumbing services nationwide. Contact us for expert solutions tailored to your needs.'
      }
    ]
  },
  components: {
    CaseStudies,
    ClientReviewCarousel,
    FaqItem },
  methods: {
    track() {
      this.$gtag.pageview("/home");
    },
  },
  data() {
    return {
    };
  }
};
</script>


<style lang="scss">
</style>