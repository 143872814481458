
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper electrical">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Electrical</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Efficient electrical installations
                    </h3>
                    <p class="client-service">
                      Commercial buildings require the highest standard of electrics to enable full efficiency throughout. We pride ourselves on listening to what our customers want before carrying out any electrical work via our multi-skilled engineers. All our work is then thoroughly tested to comply with all regulations.
                    </p>
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/gallery/24.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid electrical panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Emergency Lighting</h3>
                      <p class="panel-excerpt"> Maintenance and installation of lighting for all routes plus <em style="color: #f87e35;">exit doors, Fire </em> and <em style="color: #f87e35;"> First Aid points, lifts, stairways </em> and <em style="color: #f87e35;"> toilets.</em></p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Installation & Upgrades</h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">New offices </em> or <em style="color: #f87e35;"> equipment upgrades </em> to ensure your safety and efficiently to meet BS7671 Regulations</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Fault Finding</h3>
                      <p class="panel-excerpt">Regular testing to highlight possible faults before they arise. <em style="color: #f87e35;">Speedy response</em> with well trained electricians for efficient turn around on faults.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">PAT Testing</h3>
                      <p class="panel-excerpt">More text will go here Lorem ipsum dolor sit amet, 
                  consectetur adipiscing elit. Duis sit amet tempor lacus.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Inspection & Testing</h3>
                      <p class="panel-excerpt">Regular testing of <em style="color: #f87e35;">electrical circuits</em>. <em style="color: #f87e35;">Site surveys</em> for tailored plans to test your system at convenient intervals to meet your needs.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">                
                      <h3 class="panel-title">Reactive and Planned Maintenance</h3>
                      <p class="panel-excerpt">Ignite Facilities has a wealth of knowledge and experience in carrying out both planned and reactive maintenance works. By speaking to us in the first instance we can provide the best possible service tailored to your business needs.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Installation and System Upgrades </h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">Electrical re-wires</em> and upgrades for offices, hotels, and factories plus <em style="color: #f87e35;">Emergency</em> and <em style="color: #f87e35;">Security lighting</em>.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Service and Repairs</h3>
                      <p class="panel-excerpt">From <em style="color: #f87e35;">Emergency</em> and <em style="color: #f87e35;">Security Lighting</em> to general in-house supplies and <em style="color: #f87e35;">Power Circuits</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Intelligent Building Management Systems (IBMS & BMS) </h3>
                      <p class="panel-excerpt">By installing and maintaining <em>Building Management Systems</em> for our clients we ensure their utilities are working with maximum efficiency both economically and environmentally.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Energy Efficiency Smart Controls</h3>
                      <p class="panel-excerpt">A great way for many home owners to save money on their utility bills is by having an <em>Energy Efficient Smart Controls</em> installed. Ignite Facilities install a wide variety of domestic smart thermostats and <em>TMVs</em> to homes across Staffordshire and Cheshire.</p>
                    </div>
                  </div>	      	
                </div>
              </div>
            </div>
          </div>
        </section>      

        <!-- Services section -->
        <div class="masonry-wrapper electrical-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>            
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Crowdfunding",
  metaInfo: {
    title: 'Electrical Services - Professional Electrical Solutions by Ignite Facilities',
    titleTemplate: null,
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Electrical Services - Professional Electrical Solutions by Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Ensure safety and efficiency with professional electrical services by Ignite Facilities. Contact us for expert electrical solutions.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/electrical");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
