
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper plumbing">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>            
          </i>
          <h1 class="services-header-text">Plumbing</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Multi-skilled engineers for all your commercial plumbing needs
                    </h3>
                    <p class="client-service">
                      Ignite Facilties understands how crucial it is to have the correct supply of water and systems in place for commercial properties. Having the right plumbing in place is vital for an efficiently run heating system which is why we have our dedicated team of multi-skilled engineers on hand to help.
                    </p>
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/gallery/11.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid plumbing panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Commercial Installation and System Upgrades</h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">Office Kitchens, Water Storage tanks</em> and <em style="color: #f87e35;">Washrooms</em> for commercial properties are all part of our one solution fits all service. <em style="color: #f87e35;">Underground Water Supplies</em> along with <em style="color: #f87e35;">Radiators</em> and <em style="color: #f87e35;">Cold Water Boosters</em> can all be installed.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Thermostatic Mixer Valve Testing (TMVt)</h3>
                      <p class="panel-excerpt">To ensure the risk mitigation of Legionella and Scalding as part of H&S audits Ignite Facilities carries out extensive <em>Thermostatic Mixer Valve Testing</em> for a variety of businesses, in particular <em>Health Care, Care Homes</em> and <em>House of Multiple Occupation</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Service and Testing</h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">Water Heaters </em> and <em style="color: #f87e35;">Storage Tank </em> inspection cleaning along with <em style="color: #f87e35;">Water Quality Sampling and testing </em>. Our services also cover water heater systems and treatment of pipework.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Repairs and Maintenance</h3>
                      <p class="panel-excerpt"><em style="color: #f87e35;">Commercial Washrooms, Office Kitchens, Water Storage Tanks</em> plus solving issues with hot and cold <em style="color: #f87e35;">Water Temperature, Underground Water Supplies</em> and <em style="color: #f87e35;">Water Boosters</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Legionella Testing and Monitoring</h3>
                      <p class="panel-excerpt">By law, businesses must have a <em>Legionella Risk Assessment</em> carried out by a competent assessor. Ignite Facilities not only carries out testing but also provides monitoring services to ensure your risk assessment is up to date and any risks have been mitigated.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">                
                      <h3 class="panel-title">Chemical Dosing and Water Treatment </h3>
                      <p class="panel-excerpt">Measuring and treating water used in <em>Swimming Pools</em> and <em>Chemical Dosing Systems</em> is a service Ignite Facilities is highly experienced in carrying out to ensure clients are compliant and that their customers and their staff remain safe.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Reactive and Planned Maintenance</h3>
                      <p class="panel-excerpt">Ignite Facilities has a wealth of knowledge and experience in carrying out both planned and reactive maintenance works. By speaking to us in the first instance we can provide the best possible service tailored to your business needs.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Subterranean Water Works</h3>
                      <p class="panel-excerpt">Accessing and repairing plumbing below ground level requires specialist knowledge and equipment. Ignite Facilities carries out all inspection, repair/replacement and maintenance works for commercial and industrial businesses.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Washrooms - Installation and Maintenance </h3>
                      <p class="panel-excerpt">Planning, installing and maintaining a <em>Commercial Washroom</em> can be a nightmare for businesses, but by working with Ignite Facilities you can be assured that the process will be planned and carried out with minimal disruption to your business.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title"> Water Hygiene and Laboratory Sampling</h3>
                      <p class="panel-excerpt">Ignite Facilities carries out all manner of <em>Water Hygiene</em> activities such as <em>Inspection & Cleaning Water Storage Tanks</em>, <em>Flushing of Water Lines</em> and taking <em>Laboratory Samples</em> for testing to ensure water cleanliness.</p>
                    </div>
                  </div>	      	
                </div>
              </div>
            </div>
          </div>
        </section>     
        
        <!-- Home about section -->
        <div class="masonry-wrapper plumbing-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Crowdfunding",
  metaInfo: {
    title: 'Plumbing Solutions - Reliable Plumbing Services for Homes & Businesses',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Plumbing Solutions - Reliable Plumbing Services for Homes & Businesses | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Experience reliable plumbing solutions with Ignite Facilities. Trust our team for all your plumbing needs, big or small.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/plumbing");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
