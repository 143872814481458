import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from 'vue-gtag'
import Home from "../views/Home.vue";
import Meta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [{
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/story",
    name: "Story",
    component: () => import("../views/Story.vue")
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/Team.vue")
  },
  {
    path: "/work",
    name: "Work",
    component: () => import("../views/Work.vue")
  },
  {
    path: "/heating",
    name: "Heating",
    component: () => import("../views/Heating.vue")
  },
  {
    path: "/plumbing",
    name: "Plumbing",
    component: () => import("../views/Plumbing.vue")
  },
  {
    path: "/electrical",
    name: "Electrical",
    component: () => import("../views/Electrical.vue")
  },
  {
    path: "/aircon",
    name: "AirCon",
    component: () => import("../views/AirCon.vue")
  },
  {
    path: "/domgas",
    name: "DomGas",
    component: () => import("../views/DomGas.vue")
  },
  {
    path: "/renewables",
    name: "Renewables",
    component: () => import("../views/Renewables.vue")
  },
  {
    path: "/emergencies",
    name: "Emergencies",
    component: () => import("../views/Emergencies.vue")
  },  
  {
    path: "/packages",
    name: "Packages",
    component: () => import("../views/Packages.vue")
  },  
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Privacy-Policy.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/Terms&Conditions.vue")
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("../views/OurBlogs.vue")
  },
  {
    path: "/The-Complete-Guide-to-Facilities-Management",
    name: "The-Complete-Guide-to-Facilities-Management",
    component: () => import("../views/blog/2024/02-12-24.vue")
  },
  {
    path: "/The-Benefits-of-a-One-Stop-Maintenance-Partner-for-Commercial-Properties",
    name: "The-Benefits-of-a-One-Stop-Maintenance-Partner-for-Commercial-Properties",
    component: () => import("../views/blog/2024/16-12-24.vue")
  },
  {
    path: "/Heat-Pumps-vs-Traditional-Boilers",
    name: "Heat-Pumps-vs-Traditional-Boilers",
    component: () => import("../views/blog/2024/19-12-24.vue")
  },
  {
    path: "/Energy-Efficient-Plumbing-Solutions-to-Cut-Costs-in-Commercial-Buildings",
    name: "Energy-Efficient-Plumbing-Solutions-to-Cut-Costs-in-Commercial-Buildings",
    component: () => import("../views/blog/2024/23-12-24.vue")
  }


];

const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.use(VueGtag, {
  config: { id: "G-Y8FHL9R89D" }
}, router);

router.beforeEach((to, from, next) => {

  document.title = `${to.meta.title}`;

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next();
});

export default router;