<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="privacypolicy"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        
        <!-- Intro -->
        <section style="padding-top: 47px;"
          id="services"
          class="intro bg-white"
        >
          <div class="container">
            <div class="text-left privacy">
              <h1 class="privacy__title text-center mt-24 mb-16">
                Privacy Policy – Updated 1st June 2022
              </h1>
              <p> This privacy policy provides more detail on the information that you
              provide or that we collect from you, how we use it, and your rights in relation to our processing of
              your information.
              </p>
            </div>
            <div class="text-left privacy">
              <p>
                Third party cookies, we have no control over these cookies as they are created by third parties:
                <ul>
                  <li>FONT-AWESOME – creates cookies when you visit our website. Font Awesome provides a list of fast-loading icons used throughout our websites interface.
                  </li>
                  <li>JQUERY – creates cookies when you visit our website. Jquery provides core functionality to our website for navigation, menus, form validation etc.
                  </li>
                  <li>GOOGLE – creates cookies when you visit our website. We use Google Analytics for visitor tracking and website traffic monitoring, Google Maps to embed interactive maps within our pages and Google Recaptcha to add form security from spambots.
                  </li>
                </ul>
              </p>              
            </div>
          </div>
        </section>

        <section style="padding-top: 47px;"
        id="services"
        class="intro bg-white">
          <div class="container">
            <div class="text-center privacy">
              <h1 class="privacy__title text-center mt-24 mb-16">
                Why do we collect information?
              </h1>
                <ul class="text-left privacy">
                    We will always ensure that there is a legal basis for collecting and processing data. The main reasons
                    for processing your data will be:
                    <li>
                      To comply with our legal obligations and regulatory requirements;
                    </li>
                    <li>
                      For our legitimate interests, including:
                      <ol>
                        <li>
                          being able to effectively administer our business;
                        </li>
                        <li>
                          to provide information to our clients; and
                        </li>
                        <li>
                          to promote our business, products or services.
                        </li>
                      </ol>
                    </li>
                  <p>
                    When processing data using the ‘legitimate interests’ condition, we will carry out a balancing test of
                    our interest to ensure that our interest is not overridden by your rights, interests or freedoms.
                  </p>
                </ul>
            </div>
          </div>
        </section>

      </div>
    </main>
  </body>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: 'Privacy Policy',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Privacy Policy | Ignite Facilities'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/privacy");
    },
  },
  components: {
  }
};
</script>

<style lang="scss">
.training {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
