
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper aircon">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Air Conditioning</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      All round air conditioning for small and large buildings
                    </h3>
                    <p class="client-service">
                      Having the correct control over temperature and humidity at your leisure centre, hotel or factory is pivotal to the whole customer and staff experience. Ignite Facilities are on hand to inspect, install and test all of your air conditioning needs.
                    </p>
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/gallery/8.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid aircon panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Commercial Installation and System Upgrades</h3>
                      <p class="panel-excerpt">Designing of commercial air conditioning requirements as well as high quality <em style="color: #f87e35;">VRV</em> systems. Our team can also install <em style="color: #f87e35;">Ceiling Suspended</em> and <em style="color: #f87e35;">Cassette units</em> to provide the best solution for your property.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Reactive and Planned Maintenance</h3>
                      <p class="panel-excerpt">Ignite Facilities has a wealth of knowledge and experience in carrying out both planned and reactive maintenance works. By speaking to us in the first instance we can provide the best possible service tailored to your business needs.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Service and Repairs</h3>
                      <p class="panel-excerpt">Our team of experts will carry out a fault finding inspection and required leak testing for <em style="color: #f87e35;">VRV Systems, Ceiling Suspended</em>  and <em style="color: #f87e35;">Split Units</em> .</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Refrigeration Leak Detection and Repairs</h3>
                      <p class="panel-excerpt">Detecting and repairing refrigeration gas leaks requires specialist knowledge and Ignite Facilities has extensive experience and specialist equipment specific to F-gas detection and repair works.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Air Chilled Water Systems</h3>
                      <p class="panel-excerpt">Air Chilled Water Systems are an alternative to air-cooled systems and well known for their benefits including quicker air cooling and energy efficiency. Ignite Facilities provides all planning, installation and maintenance works for Water Cooling Systems.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">                
                      <h3 class="panel-title">F-Gas Logs</h3>
                      <p class="panel-excerpt">With every contracted site we will produce and update your mandatory <em style="color: #f87e35;">F-Gas Log book</em>, which details ways to protect the environment.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Comfort Cooling and Ventilation</h3>
                      <p class="panel-excerpt">Installation and maintenance of systems designed to cool the air by forcing hot air out of an enclosed space in order to make the environment more comfortable. <em>Air Conditioning</em> and <em>Large Comfort Cooling Systems</em> can be maintained by our experienced engineers.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Intelligent Building Management Systems (IBMS & BMS) </h3>
                      <p class="panel-excerpt">By installing and maintaining <em>Building Management Systems</em> for our clients we ensure their utilities are working with maximum efficiency both economically and environmentally.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Legionella Testing and Monitoring</h3>
                      <p class="panel-excerpt">By law, businesses must have a <em>Legionella Risk Assessment</em> carried out by a competent assessor. Ignite Facilities not only carries out testing but also provides monitoring services to ensure your risk assessment is up to date and any risks have been mitigated.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">VRV & Split Systems</h3>
                      <p class="panel-excerpt">Ignite Facilities has extensive experience of air conditioning systems and provides <em>installation & maintenance</em> services for both <em>VRV and Split systems</em>, so you can rest assured that your domestic or commercial premise in the safest hands.</p>
                    </div>
                  </div>	      	
                </div>
              </div>
            </div>
          </div>
        </section>   

        <!-- Home about section -->
        <div class="masonry-wrapper aircon-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Crowdfunding",
  metaInfo: {
    title: 'Air Conditioning - Efficient Cooling Solutions by Ignite Facilities',
    titleTemplate: null,
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Air Conditioning - Efficient Cooling Solutions by Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Keep your space cool and comfortable with efficient air conditioning solutions by Ignite Facilities. Contact us for more information.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/aircon");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
