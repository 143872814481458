<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="contact"
      itemscope
      itemtype="http://schema.org/WebPage"
    >

    <div class="masonry-wrapper contact">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Contact Us</h1>
        </div>            
      </div>      
    </div>
        
    <main class="main-wrap">
      <div class="content-wrap">

        <!-- Contact Buttons and Map -->
        <Section class="image-text floors colour2 pad1">
          <!-- Services section -->
          <div class="masonry-wrapper contact-form">
            <div class="masonry contact-form"> 
              <div class="overlay masonry-item">
                <div class="masonry-title mt-32">
                  <h3 class="text-center">
                    There are many ways to get in touch with us.<br>
                    You can call, email or why not book a visit using our contact form below.
                  </h3>
                  <!-- Contact Buttons -->
                  <div class="contact-button-container pad2">            
                    <a href="tel:01782706984" class="client-button-container">
                      <button class="client-button-inverted">
                        <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                          <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                          <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                        </svg>
                        <span>Call Us</span>
                      </button>
                    </a>

                    <a href="mailto:info@ignitefacilities.co.uk" class="client-button-container">
                      <button class="client-button-inverted">
                        <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
                          <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                          <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                        </svg>
                        <span>Email Us</span>
                      </button>
                    </a>
                  </div>                 
                </div>
              </div>             
            </div>
          </div>                   
        </Section>

        <!-- Google Map -->
        <Grid>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2854.7687794057415!2d-2.1678040650707464!3d53.01020858619487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a418a9f2787bd%3A0x4449a6cd62e1e99a!2sIgnite%20Facilities%20Ltd!5e0!3m2!1sen!2suk!4v1684239393962!5m2!1sen!2suk"
            width="100%"
            height="450"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
            aria-hidden="false" 
            tabindex="0">
          </iframe>
        </Grid>

        <!-- Contact Form -->
        <Section wrap class="contact-bg colour2 pad4">
          <div class="contact-form-bg">          
            <div class="text-center above-contact-form">
              <h1 class="tight pad-half">Send Us A Message</h1>
              <p class="pb">
                If you would like to send us a quick message simply fill in
                the form below and press the send button.
              </p>
            </div>

            <Grid center class="contact-form-grid">
              <Column :column-width="70" :below-tablet-width="100">
                <Grid pad class="contact-grid" :class="['contact-form', errors ? 'errors' : '']">
                  <Column :column-width="50" :below-laptop-width="100">
                    <div class="form-group">
                      <label v-if="!nameError" for="name">Name</label>
                      <label v-if="nameError" class="errorLabel" for="name"
                        >* You must enter a name</label
                      >

                      <input
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        :class="[nameError ? 'error' : '']"
                        v-model="name"
                        required
                      />
                    </div>
                  </Column>
                  <Column :column-width="50" :below-laptop-width="100">
                    <div class="form-group">
                      <label for="company">Company</label>
                      <input
                        type="text"
                        v-model="company"
                        placeholder="Company Name"
                        name="company"
                      />
                    </div>
                  </Column>

                  <Column :column-width="50" :below-laptop-width="100">
                    <div class="form-group">
                      <label v-if="!emailError" for="email">Email</label>
                      <label v-if="emailError" class="errorLabel" for="email"
                        >* You must enter either a phone or valid email</label
                      >
                      <input
                        type="email"
                        v-model="email"
                        :class="[emailError ? 'error' : '']"
                        placeholder="Email"
                        name="email"
                      />
                    </div>
                  </Column>

                  <Column :column-width="50" :below-laptop-width="100">
                    <div class="form-group">
                      <label v-if="!telephoneError" for="telephone">Telephone</label>
                      <label v-if="telephoneError" class="errorLabel" for="telephone"
                        >* You must enter either a phone or valid email</label
                      >
                      <input
                        type="number"
                        :class="[telephoneError ? 'error' : '']"
                        placeholder="Phone Number"
                        name="telephone"
                        v-model="telephone"
                      />
                    </div>
                  </Column>                                   
                </Grid>
                <Column Column :column-width="100" :below-laptop-width="100">
                    <div class="form-group-full">
                      <label v-if="!messageError" for="message">Your Message</label>
                      <label v-if="messageError" class="errorLabel" for="message"
                        >* You must enter a message</label
                      >
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        :class="[messageError ? 'error' : '']"
                        rows="8"
                        v-model="message"
                      ></textarea>
                    </div>
                  </Column> 
                <Column :column-width="100" :below-laptop-width="100">
                    <a
                      :class="['button contact-form-button', sending ? 'sending' : '']"
                      href="#"
                      @click.prevent="sendMessage"
                      >{{ sendButtonText }}</a
                    >

                    <p
                      :class="[
                        'label',
                        errors || sendFailure ? 'error' : 'success',
                        !sendSucceeded && !errors && !sendFailure ? 'hide' : ''
                      ]"
                    >
                      &nbsp;
                      <span v-if="errors"
                        >Please fix the issues above and click
                        <b>Send Message</b> again</span
                      >
                      <span v-if="sendSucceeded"
                        >Your message was sent successfully. We will contact you as soon as we can.
                      </span
                      >

                      <span v-if="sendFailure">{{ sendFailure }}</span>
                    </p>
                  </Column>
              </Column>
            </Grid>
          </div>
        </Section>

      <!-- Contact us Section-->
      <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
import modal from "@/components/ContactModal.vue";
export default {
  name: "contact",
  metaInfo: {
    title: 'Contact - Get in Touch with Ignite Facilities for Your Building Needs',
    titleTemplate: null,
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Contact - Get in Touch with Ignite Facilities for Your Building Needs'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Get in touch with Ignite Facilities for all your building requirements. Our team is ready to provide efficient solutions for you.'
      }
    ]
  },
  components: {
    CaseStudies,
    modal // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      name: "",
      company: "",
      email: "",
      telephone: "",
      message: "",
      nameError: false,
      emailError: false,
      telephoneError: false,
      messageError: false,
      sending: false,
      sendFailure: "",
      sendSucceeded: false
    };
  },
  computed: {
    errors() {
      return (
        this.nameError ||
        this.emailError ||
        this.telephoneError ||
        this.messageError
      );
    },
    sendButtonText() {
      return this.sending ? "Sending..." : "Send Message";
    }
  },
  methods: {
    track() {
      this.$gtag.pageview("/contact");
    },
    validEmail(email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    isEmptyText(input) {
      return input == null || input.trim() === "";
    },
    sendMessage() {
      // If we are already sending...
      if (this.sending)
        // Ignore
        return;

      // Store this
      let me = this;

      // Clear send errors
      this.sendFailure = "";
      this.sendSucceeded = false;

      // Check we have a name
      this.nameError = this.isEmptyText(this.name);

      // Email or telephone must be provided
      this.emailError = this.telephoneError =
        !this.validEmail(this.email) && this.isEmptyText(this.telephone);

      // Check we have a message
      this.messageError = this.isEmptyText(this.message);

      // If we have any errors...
      if (this.errors) {
        // Scroll to top of form
        document.querySelector(".above-contact-form").scrollIntoView({
          behavior: "smooth",
          block: "start" // scroll to bottom of target element
        });

        // Stop there
        return;
      }

      // Good to go... try sending email and setting sending status
      this.sending = true;

      this.axios
        .post("/sendemail", {
          name: this.name,
          company: this.company,
          email: this.email,
          telephone: this.telephone,
          message: this.message
        })
        .then(function(response) {
          // No longer sending
          me.sending = false;

          console.log("got response");
          console.log(response);

          // Make sure response says "Sent ok :)"
          if (response.data == "Sent ok :)") {
            // All good
            me.sendSucceeded = true;

            // Clear all fields
            me.name = me.company = me.email = me.telephone = me.message = "";
          } else {
            // Doh... unknown response
            me.sendFailure = "Unknown response from mail sender server.";
          }
        })
        .catch(function(error) {
          // No longer sending
          me.sending = false;

          console.log("got error");
          console.log(error.response);

          // Doh... show error
          me.sendFailure = me.isEmptyText(error.response.data)
            ? error.response.statusText
            : error.response.data;
        });
    }
  }
};
</script>



<style lang="scss">
@import '../styles/base/_mixins.scss';
.contact {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}

.contact-button-container {
  text-align: center;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  grid-gap: 2em;

  @include breakpoint-small-mobile {
    flex-direction: column;
  }

  @include breakpoint-mobile {
    flex-direction: column;
  }

  .client-button-container {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
    display: block;
  }
}
</style>
