<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="about"
      itemscope
      itemtype="http://schema.org/WebPage"
    >

    <div class="masonry-wrapper about">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Our Story</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  <div class="client-grid-column-50" >
                    <img class="our-team-img" src="/img/gallery/16.jpg" alt="Chris, Dan and Tony standing by Ignite Facilities work vans" />
                  </div>
                  <div class="client-grid-column-50">
                    <h2 class="intro__text text-center">
                      Decades of experience supplying quality facilities management services
                    </h2>
                    <p class="client-service">
                      Founded by Chris Brass, who has worked in the industry all of his adult life, Ignite Facilities has grown to be a reputable company covering the whole of Staffordshire and surrounding areas. With an exceptional customer service reputation, all of our engineers are Gas Safety registered, multi-skilled and always at hand to provide the best advice to our customers. Due to our team of multi-skilled engineers who are all locally employed, we are able to deliver a range of services including assisting large maintenance teams on site at industrial and commercial properties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- About us Section-->
        <section style="padding-top: 47px;" id="services" class="intro quarternary-back">
          <!-- About us Container -->
          <h1 class="title-prime-colour" style="text-align: center;">Our Work in the Community</h1>
          <div class="image-mosaic mt-32">
            <div
              class="card card-tall card-wide"
              style="background-image: url('/img/charities/eggs.jpeg')"
              alt="A shopping trolley full of easter eggs donated to a children's charity by Ignite Facilities."
            ></div>
            <div
              class="card card-tall"
              style="background-image: url('/img/charities/CovidFoodAssist.jpeg')"
              alt="Chris Brass holding a bottle of milk donated by Ignite Facilities in response to the Covid-19 community food assistance campaign."
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/Gingerbread.jpg')"
              alt="The Gingerbread Centre charity logo"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/GingerFundraiserDay.jpeg')"
              alt="A table centrepiece of the Gingerbread Center mascot on display at the Gingerbread Centre Fundraiser Event."
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/GingerSponsorDay.jpeg')"
              alt="A placard showing Ignite Facilities as one of the main sponsors of the Gingerbread Center Fundraiser Day."
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/HanleyTownFC.jpeg')"
            ></div>
            <div
              class="card card-wide"
              style="background-image: url('/img/charities/HanleyTownFCKidsSponsor.jpeg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/HanleyTownSponsor.jpeg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/mind.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/seed.jpeg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/matchday.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/matchday2.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/charities/matchday3.jpg')"
            ></div>
          </div>
          
        </section>

        <!-- Video -->
        <Section class="image-text floors mt-48 " wrap>
          <Grid pad center middle >
            <Column :below-laptop-width="100" :column-width="100" below-laptop-text-center>
              <p
              class="home-intro-text mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                We pride ourselves on working in the community to help tackle issues that some of our 
                most vulnerable members of the community face every day.
              <br> <br> 
                Not only do we help to sponsor local youth football clubs but we also help out with pitch seeding to ensure the kids have great pitches to play on. Along with this we also sponsor teams at Hanley Town Football Club, a local youth football club in Stoke-on-Trent. We also donate to the local easter egg run, to help put a smile on the faces of those kids who would otherwise go without an easter egg at such a special time of the year.
                <br> <br> 
                We also responded to the Covid-19 pandemic by doing the shopping for many local people including the elderly and medically at risk.
              </p>
            </Column>
          </Grid>
        </Section>

        
        
      

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Story",
  metaInfo: {
    title: 'Our Story - Leading Mechanical Contractor in Staffordshire',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Our Story - Leading Mechanical Contractor in Staffordshire | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Learn about Ignite Facilities, the leading mechanical contractor in Staffordshire, providing top-notch services for over 25 years.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/story");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
  
</style>
