
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper renewables">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Heat Pumps</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/ignite/services/renewable1.jpg" alt="" />
                  </div>
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Leading the Way with Heat Pump Solutions
                    </h3>
                    <p class="client-service">
                      Looking to upgrade your heating system? At Ignite Facilities, we bring cutting-edge, energy-efficient heat pumps to homes and commercial spaces alike. By leveraging the latest in heat pump technology, such as our trusted partner Daikin, we ensure your comfort, energy savings, and sustainability goals are met seamlessly.
                    </p>
                    <div>
                      <router-link to="Contact">
                        <button class="client-button-orange-solid-wide">
                          Get in touch to see how we can help you today
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Why consider Heat Pumps? -->
        <section id="services" class="secondary-back services-icon-columns">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="title-row">
                  <h3 class="section-title">Why consider Heat Pumps?
               </h3>
                </div>
              </div>

              <div class="services-cont orange">

                <div class="service">
                  <div>
                    <img class="service__icon" src="/img/ignite/services/ignite-ico1.png" alt="call">
                    <h4 class="service__title">Cost Savings:</h4>
                    <p class="service__text">
                      Heat pumps can significantly reduce your energy bills by using a fraction of the energy consumed by traditional heating systems.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <img class="service__icon" src="/img/ignite/services/ignite-ico2.png" alt="call">
                    <h4 class="service__title">Energy Efficiency:</h4>
                    <p class="service__text">
                      Unlike boilers, which generate heat through combustion, heat pumps move existing heat from the air or ground into your building. This process makes them far more efficient, typically achieving efficiencies of over 300%.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <img class="service__icon" src="/img/ignite/services/ignite-ico3.png" alt="call">
                    <h4 class="service__title">Environmentally Friendly:</h4>
                    <p class="service__text">
                      Heat pumps produce far less CO2 compared to gas or oil boilers, making them a greener choice.
                    </p>
                  </div>
                </div>

                <div class="service">
                  <div>
                    <img class="service__icon" src="/img/ignite/services/ignite-ico4.png" alt="call">
                    <h4 class="service__title">Year-Round Comfort:</h4>
                    <p class="service__text">
                      Heat pumps aren’t just for heating. Many models can also provide cooling during the warmer months, making them versatile year-round solutions.
                    </p>
                  </div>
                </div>

              </div>

              <div>
                <router-link to="Contact">
                  <button class="client-button-orange-solid-wide">
                    Get in touch to see how we can help you today
                  </button>
                </router-link>                  
              </div>
            </div>
          </div>
        </section>

        <!-- Full width Body Text Grey-->
        <div class="service-full-width-text-body-cont grey">
          <div class="service-full-width-headline">
            <h4>Comparing Heat Pumps and Boilers</h4>
          </div>                 
        </div>
        
        <!-- Heat Pumps Container-->
        <section class="text-center">
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/ignite/services/renewable2.jpg" alt="" />
                  </div>
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Heat Pumps:
                    </h3>
                    <p class="client-service list">
                      <ul>
                        <li>
                          <em>Efficiency:</em> Typically over 300% efficiency (produces more energy than they consume).
                        </li>
                        <li>
                          <em>Lifespan:</em> Generally lasts 15-20 years with proper maintenance.
                        </li>
                        <li>
                          <em>Environmental Impact:</em> Significantly lower CO2 emissions; renewable energy options available.
                        </li>
                        <li>
                          <em>Running Costs:</em> Lower operating costs due to energy efficiency.
                        </li>
                        <li>
                          <em>Installation:</em> Typically higher initial costs but benefits from grants and incentives.
                        </li>
                      </ul>
                    </p>
                    <div>
                      <router-link to="Contact">
                        <button class="client-button-orange-solid-wide">
                          Get in touch to see how we can help you today
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Traditional Boiler Container-->
        <section class="text-center">          
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Traditional Boilers:
                    </h3>
                    <p class="client-service list">
                      <ul>
                        <li>
                          <em>Efficiency:</em> Around 70-90% (less efficient due to combustion losses).
                        </li>
                        <li>
                          <em>Lifespan:</em> Typically lasts 10-15 years.
                        </li>
                        <li>
                          <em>Environmental Impact:</em> Higher CO2 emissions due to combustion.
                        </li>
                        <li>
                          <em>Running Costs:</em> Can be higher, especially with fluctuating fuel costs.
                        </li>
                        <li>
                          <em>Installation:</em> Lower initial costs but no grants available for traditional systems.
                        </li>
                      </ul>
                    </p>
                    <div>
                      <router-link to="Contact">
                        <button class="client-button-orange-solid-wide">
                          Get in touch to see how we can help you today
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/ignite/services/renewable3.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Full width Body Text Grey-->
        <div class="service-full-width-text-body-cont grey">
          <div class="service-full-width-headline">
            <h4>Grants for Heat Pumps – Helping You Save</h4>
          </div>                 
        </div>

        <!-- Residential Container-->
        <section class="text-center">
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/ignite/services/renewable4.jpg" alt="" />
                  </div>
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Residential
                    </h3>
                    <p class="client-service">
                      For residential properties, the UK government offers the Boiler Upgrade Scheme (BUS), providing grants of up to £5,000 towards the installation of an air source heat pump. Our experts at Ignite Facilities can guide you through the process of securing these grants, making the transition to a more efficient heating system even easier.
                    </p>
                    <h3 class="mb-32" >
                      Commercial Buildings & New Builds:
                    </h3>
                    <p class="client-service">
                      While grants may primarily focus on residential properties, Ignite Facilities provides tailored heat pump solutions for new build projects and commercial spaces, ensuring that your system is designed for optimal efficiency and performance.
                    </p>
                    <div>
                      <router-link to="Contact">
                        <button class="client-button-orange-solid-wide">
                          Get in touch to see how we can help you today
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Daikin Container-->
        <section class="text-center">
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      Why Daikin Heat Pumps?
                    </h3>
                    <p class="client-service list">
                      <ul>
                        <li>
                          <em>Unparalleled Efficiency:</em>  Daikin heat pumps are designed for maximum energy efficiency, providing superior heating performance with lower running costs.
                        </li>
                        <li>
                          <em>Quiet Operation:</em>  Enjoy a peaceful environment thanks to Daikin’s ultra-quiet operation.
                        </li>
                        <li>
                          <em>Versatile Options:</em> Whether you need an air-to-water, ground-source, or hybrid solution, Daikin offers a range of options to fit any need.
                        </li>
                        <li>
                          <em>Smart Controls:</em> Daikin heat pumps come equipped with smart controls, allowing you to easily manage your home’s temperature from anywhere.
                        </li>
                        <li>
                          <em>Scop</em> -seasonal coiffciency operational procedure - 95% boiler = 0.95scop - heat pump is 300% - 3 scop
                        </li>
                      </ul>
                    </p>
                    <div>
                      <router-link to="Contact">
                        <button class="client-button-orange-solid-wide">
                          Get in touch to see how we can help you today
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/ignite/services/renewable5.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Full width Body Text -->
        <div class="service-full-width-text-body-cont">
          <div class="service-full-width-headline">
            <h4>Interested? Contact Us!</h4>
          </div>
          <div class="service-full-width-text-body">
            <p>
              Experience the Ignite Facilities difference. Get in touch with us today to explore your heat pump options, discuss potential savings, or start your journey to a more efficient heating system.
            </p>
          </div>                  
        </div>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid heating panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Installation</h3>
                      <p class="panel-excerpt">Planning design of boiler rooms and heating systems. We can take care of all fitting needs from boilers systems to radiator requirements for large premises. Once fitted by our team we will then using our thorough testing services to ensure everything is as it should be.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Installation and Service Upgrades</h3>
                      <p class="panel-excerpt">Installation of <em style="color: #f87e35;">Water Heaters, Radiators, Furnace Burners and Oil Fired installations</em> plus all <em style="color: #f87e35;">Kitchen Interlock and Ventilation Systems</em>. Our team can take care of all pipework and underground gas supplies.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Service and Testing </h3>
                      <p class="panel-excerpt">All heating requirements including <em style="color: #f87e35;">Oil, LPG,</em> and <em style="color: #f87e35;">AHUs </em>along with <em style="color: #f87e35;">Catering facilities</em> and <em style="color: #f87e35;">Kitchen systems</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Repairs and Maintenance</h3>
                      <p class="panel-excerpt">Repairs cover anything from <em style="color: #f87e35;">Water Heaters, Boilers, Oil, LPG</em> and <em style="color: #f87e35;">Underground Gas</em> supplies. Our team will also take care of <em style="color: #f87e35;">Gas and Water Leaks</em> and provide a solution for <em style="color: #f87e35;">Commercial Heating</em> and <em style="color: #f87e35;">Gas Issues</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Safety Inspections </h3>
                      <p class="panel-excerpt">Our team visits your site to get to the route of any heating related issue. Our inspections will check and monitor anything from no heating or water to leakages and frozen pipes.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">                
                      <h3 class="panel-title">Intelligent Building Management Systems (IBMS & BMS) </h3>
                      <p class="panel-excerpt">By installing and maintaining <em>Building Management Systems</em> for our clients we ensure their utilities are working with maximum efficiency both economically and environmentally.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Reactive and Planned Maintenance</h3>
                      <p class="panel-excerpt">Ignite Facilities has a wealth of knowledge and experience in carrying out both planned and reactive maintenance works. By speaking to us in the first instance we can provide the best possible service tailored to your business needs.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Gas pressure testing and Purging</h3>
                      <p class="panel-excerpt">In order to keep clients legally compliant, Ignite Facilities carries out <em> Gas Testing and Purging</em> as part of the reporting that is legally required for any gas installations that are carried out.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Air Source Heat Pumps</h3>
                      <p class="panel-excerpt">One of the ways businesses can save on their heating bills is through the installation and proper maintenance of <em>Air Source Heat Pumps</em>. Our engineers regularly carry out installations and servicing for our clients located across Staffordshire and Cheshire.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Natural gas, LPG and Oil </h3>
                      <p class="panel-excerpt">Our engineers have a wealth of experience carrying out the installation and maintenance of boilers that use <em>Natural Gas, Liquefied Petroleum Gas or Oil</em>. If your business requires a dedicated facilities management company call us today and let Ignite Facilities look after your business. </p>
                    </div>
                  </div>	      	
                </div>
              </div>
            </div>
          </div>
        </section>  

        <!-- Home about section -->
        <div class="masonry-wrapper heating-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Renewables",
  metaInfo: {
    title: 'Blog: Heat Pumps - Leading the Way with Heat Pump Solutions',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Heat Pumps - Leading the Way with Heat Pump Solutions'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Looking to upgrade your heating system? At Ignite Facilities, we bring cutting-edge, energy-efficient heat pumps to homes and commercial spaces alike.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/renewables");
    },
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
